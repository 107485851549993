* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  overflow-x: hidden !important;
}
.container {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

body {
  background-color: #f6f6f6;
  padding-top: 80px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
   position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 10% 0 10%;
  z-index: 100;
  background-color: transparent;
  transition: background-color 0.3s ease

}
.bar {
  fill: gray;
}
.scrolled{
  background-color: rgb(241, 244, 255);
}
.navbar-menu-container {
  display: none;
}

.navbar-logo{
  
  max-height: 60px
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
  transition: font-size 0.5s ease;
}

.navbar-links-container a:hover {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  font-weight: 600;
}

.desktop-menu-button {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
  transition: font-size 0.5s ease;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.desktop-menu-button:hover {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  font-weight: 600;
}

.integration_image {
  height: 5rem;
  margin-right: 1rem;
  opacity: 70%;
  ;
}

.demo-container{
  margin: 50px 0 0 20px;
}

.play-button-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex; /* Aggiungi questa riga */
  justify-content: center; /* Aggiungi questa riga */
  align-items: center; /* Aggiungi questa riga */
  
}

#play_button {
  
  width: 30%;
  cursor: pointer;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  background-color:  #f6f6f6; /* Opacità al 100% */;
  border: 10px solid white;
  border-radius: 50%;
  background-clip: padding-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: opacity 2s ease-in-out;
  
  
}

#play_button:hover {
  width: 35%;
}




.master-container{
  margin:0% 10%
}


.color-seconday-header{
  color: rgb(121, 197, 7);
 
  text-align: center;
  
}

.boxform-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}


.about-image {
  filter: hue-rotate(240deg);
  height: 1000px;
}







.primary-button {
  padding: 0.9rem 1.75rem;
  background-color:#5271ff ;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
  color: white;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
 
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


#mvp-container{
  padding-top: 0.1%;
  background-color: white; 
  border-radius: 10px; 
  min-width: 100%;
  min-height: 100%
}

#service-selection-container{
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

.chat-container {
  position: relative;
  height: 200px; /* Aggiungi un'altezza fissa in base alle tue esigenze */
}


.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  appearance: none;
}

select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #0077ff;
}

option {
  font-size: 1rem;
}

.selected-option {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}


.perchi-section-container{
  margin-top: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.perchi-text-container {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
}




.custom-button {
  display: inline-block;
  background-color: #0077ff;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin: 0% 0% 5% 5%;
  width: 90%;
}

.custom-button:hover {
  background-color: #0059b3;
}

.custom-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #0077ff;
}





.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 5px;
}

.round-button {
  border: none;
  border-radius: 5px;
  background-color: transparent;
  color: green;
  text-align: center;
  font-size: 24px;
  padding: 15px 32px;
  cursor: pointer;
}

#start_button{
  background:transparent
}





.king-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: black;
  line-height: 4.5rem;
  max-width: 600px;
  font-weight: 500;
}



.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: black;
  line-height: 4.5rem;
  max-width: 600px;
  font-weight: 200;
}

.secondary-heading {
  font-size: clamp(1.1rem, 3vw, 2.5rem);
  color: black;
  line-height: 1.2;
  max-width: 600px;
}

.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.secondary-button {
  margin-top: 1rem;
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #e48f0f;
}
.about-section-container {
  margin-top: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}


.about-section-container-inverted {
  margin-top: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}


.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
  
}
.about-section-image-container {
  flex: 0.6;
  margin-right: 2rem;
  

}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #5ce1e6;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;

  align-items: center;
  padding: 1rem;
  border-radius: 3rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 1rem 1rem;
}

.esito {
  
  color: #0059b3;
  font-size: 1.2rem;
  
}

.button-light-green {
  background-color: rgb(235, 244, 235);
  pointer-events: none;
  color: darkgrey;
  padding: 1rem 1rem;
  margin-top: 0px;
  
}




.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 10rem;
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 5rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  height: 445px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 1rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.work-image {
  max-height: 190px ;
}

.contact-form {
  background-color: white;
  margin: auto;
}

@media (max-width:1150px) {
  nav{
    padding: 0 15px 0 15px;
  }
  .navbar-logo {
    max-width: 10rem;
  }
}



@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }

  .master-container{
    margin: 0px;
  }

  p{
    line-height: 1.5rem;
  }

  .primary-heading {
    line-height: 2.5rem;
    font-size: 25px ;
    color: #5271ff;
    font-weight: 500;
  }

  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }

 
  .king-heading{
    line-height: 2.5rem;
    text-align: center;

  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.3);
    border-radius: 10px;

  }
  .about-section-container {
    flex-direction: column-reverse;
  }

  .about-section-container-inverted {
    flex-direction: column;
    margin-top: 50px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.3);
    border-radius: 10px;
   }


  .home-image-section {
    width: 100%;
    max-width: 400px;
  }


  .about-section-image-container {
    width: 100%;
    max-width: 400px;
    padding: 0 5px 5px 5px;
  }

  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }


  .home-banner-container{
    padding: 0;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
    margin-top: 0;
    border-radius: 10px;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }


  .mobile-menu-button{
    text-decoration: none
  }

}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}